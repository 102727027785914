import React, { useState } from 'react';
import { Upload, FileText, CheckCircle, Loader, ArrowRight } from 'lucide-react';
import {getAuth} from "firebase/auth";

export interface IdentityResult {
    atomicEvents:            AtomicEvent[];
    enrichmentOpportunities?: EnrichmentOpportunities;
    userId:                  string;
    identityCore:            IdentityCore;
    skillGraph:              SkillGraph;
    createdAt:               Date;
    profileCompleteness:     ProfileCompleteness;
}

export interface AtomicEvent {
    impact:      Impact;
    context:     Context;
    timeline:    Timeline;
    eventId:     string;
    connections: Connections;
    details:     Details;
    skills:      Skills;
    eventType:   string;
}

export interface Connections {
    prerequisites?: string[];
    supports?:      string[];
    relatedEvents?: string[];
}

export interface Context {
    industry:     string;
    environment:  Environment;
    organization: string;
}

export interface Environment {
    department:  string;
    teamSize:    TeamSize;
    companyType: string;
    companySize: string;
}

export type TeamSize = number | string;

export interface Details {
    mainFocus:        string[];
    description:      string;
    title:            string;
    responsibilities: string[];
}

export interface Impact {
    beneficiaries: string[];
    qualitative:   string[];
    scope:         string;
    quantitative:  string[];
}

export interface Skills {
    methodologies: string[];
    technical:     string[];
    tools:         string[];
    soft:          string[];
}

export interface Timeline {
    endDate:        string;
    durationMonths: number;
    startDate:      Date;
}

export interface EnrichmentOpportunities {
    potentialPerspectives?: PotentialPerspective[];
    identityGaps?:          IdentityGap[];
    missingContext?:        MissingContext[];
}

export interface IdentityGap {
    context:            string;
    suggestedQuestions: SuggestedQuestion[];
    aspect:             string;
    priority:           string;
}

export interface SuggestedQuestion {
    question:       string;
    questionId:     string;
    context:        string;
    questionType:   string;
    expectedImpact: ExpectedImpact;
    target:         Target;
    responseFormat: ResponseFormat;
    followUps?:      string[];
    priority:       string;
}

export interface ExpectedImpact {
    profileCompletenessGain: number;
}

export interface ResponseFormat {
    type:       string;
}

export interface Target {
    skillId: string;
    aspect:  string;
    eventId: string;
}

export interface MissingContext {
    missingAspects:     string[];
    suggestedQuestions: SuggestedQuestion[];
    eventId:            string;
}

export interface PotentialPerspective {
    evidence:   string[];
    type:       string;
    confidence: string;
}

export interface IdentityCore {
    currentStatus: CurrentStatus;
    contactInfo:   ContactInfo;
}

export interface ContactInfo {
    phone:          string;
    email:          string;
    onlinePresence: OnlinePresence;
    location:       string;
    name:           string;
}

export interface OnlinePresence {
    github:    string;
    portfolio: string;
    linkedin:  string;
}

export interface CurrentStatus {
    yearsOfExperience: number;
    industries:        string[];
    latestRole:        string;
    careerLevel:       string;
}

export interface ProfileCompleteness {
    missingAspects:        MissingAspect[];
    score:                 number;
    suggestedImprovements: string[];
}

export interface MissingAspect {
    impactOnCompleteness: number;
    aspect:               string;
}

export interface SkillGraph {
    technicalSkills: TechnicalSkill[];
    softSkills:      SoftSkill[];
}

export interface SoftSkill {
    demonstratedIn: string[];
    context:        string;
    name:           string;
}

export interface TechnicalSkill {
    level:       string;
    context:     string[];
    recentUsage: string;
    name:        string;
}


const OnboardingFlow = () => {
    const [step, setStep] = useState(1);
    const [file, setFile] = useState<File | null>(null);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [loading, setLoading] = useState(false);
    const [identityData, setIdentityData] = useState<IdentityResult | null>(null);
    const [answers, setAnswers] = useState<Record<string, string>>({});
    const auth = getAuth();


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            setFile(selectedFile);
        }
    };

    const handleSubmitInitial = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            if (file) {
                formData.append('cv_file', file);
            }
            formData.append('user_id', auth.currentUser!.uid); // Should come from auth context
            formData.append('plan', "not free"); // Or whatever plan is appropriate
            formData.append('additional_info', additionalInfo); // Or whatever plan is appropriate

            const response = await fetch('http://localhost:8002/create_cv', {
                method: 'POST',
                body: formData,
            });

            const responseData = await response.json();
            console.log(typeof responseData)
            const json: IdentityResult = JSON.parse(responseData)

            // console.log(data)
            setIdentityData(json);
            // console.log(identityData)
            setStep(3);
        } catch (error) {
            console.error('Error creating identity:', error);
        } finally {
            setLoading(false);
        }
    };

    const steps = [
        { id: 1, title: "Upload CV" },
        { id: 2, title: "Add Details" },
        { id: 3, title: "Enhance" },
        { id: 4, title: "Complete" }
    ];

    // ... (keep handleFileChange and handleSubmitInitial as they are)

    const renderProgressBar = () => (
        <div className="mb-8">
            <div className="flex justify-between mb-2">
                {steps.map((s) => (
                    <span
                        key={s.id}
                        className={`text-sm ${
                            step >= s.id ? 'text-blue-600 font-medium' : 'text-gray-400'
                        }`}
                    >
                        {s.title}
                    </span>
                ))}
            </div>
            <div className="relative pt-1">
                <div className="flex h-2 overflow-hidden bg-gray-100 rounded-full">
                    <div
                        className="transition-all duration-500 ease-in-out bg-blue-600"
                        style={{ width: `${((step - 1) / (steps.length - 1)) * 100}%` }}
                    />
                </div>
            </div>
        </div>
    );

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="space-y-6">
                        <div className="text-center">
                            <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent mb-4">
                                Welcome to CVSync.io
                            </h2>
                            <p className="text-gray-600">Let's create your professional profile together, starting with your CV</p>
                        </div>

                        <div className="border-2 border-dashed border-gray-300 hover:border-blue-500 transition-all cursor-pointer rounded-xl bg-gray-50">
                            <div className="text-center p-8">
                                <div className="mb-4 relative">
                                    <div className="w-20 h-20 bg-blue-100 rounded-full mx-auto flex items-center justify-center">
                                        <Upload className="h-10 w-10 text-blue-600" />
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h3 className="text-lg font-medium text-gray-900">Drop your CV here</h3>
                                    <p className="text-sm text-gray-500">or</p>
                                    <label className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer">
                                        <span>Choose file</span>
                                        <input
                                            type="file"
                                            className="sr-only"
                                            accept=".docx"
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                    <p className="text-xs text-gray-400">DOCX format, up to 10MB</p>
                                </div>
                            </div>
                        </div>

                        {file && (
                            <div className="flex items-center space-x-2 p-4 bg-blue-50 border border-blue-100 rounded-lg">
                                <FileText className="w-4 h-4 text-blue-600" />
                                <p className="text-sm text-blue-600">
                                    {file.name} uploaded successfully
                                </p>
                            </div>
                        )}

                        <button
                            onClick={() => setStep(2)}
                            disabled={!file}
                            className="w-full py-4 px-6 text-lg font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed transition-colors duration-200 flex items-center justify-center space-x-2"
                        >
                            <span>Continue</span>
                            <ArrowRight className="w-5 h-5" />
                        </button>
                    </div>
                );

            case 2:
                return (
                    <div className="space-y-6">
                        <div className="text-center">
                            <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent mb-4">
                                Tell us more about yourself
                            </h2>
                            <p className="text-gray-600">Help us understand your experience and career goals better</p>
                        </div>

                        <div className="bg-white rounded-xl shadow-lg p-6">
                            <textarea
                                value={additionalInfo}
                                onChange={(e) => setAdditionalInfo(e.target.value)}
                                rows={6}
                                className="w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base resize-none"
                                placeholder="Share your career highlights, key achievements, or any specific areas you'd like to focus on..."
                            />
                        </div>

                        <button
                            onClick={handleSubmitInitial}
                            className="w-full py-4 px-6 text-lg font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed transition-colors duration-200 flex items-center justify-center space-x-2"
                            disabled={loading}
                        >
                            {loading ? (
                                <div className="flex items-center space-x-2">
                                    <Loader className="w-5 h-5 animate-spin" />
                                    <span>Analyzing your profile...</span>
                                </div>
                            ) : (
                                <div className="flex items-center">
                                    <span>Continue</span>
                                    <ArrowRight className="w-5 h-5 ml-2" />
                                </div>
                            )}
                        </button>
                    </div>
                );

            case 3:
                return (
                    <div className="space-y-6">
                        <div className="text-center">
                            <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent mb-4">
                                Enhance your profile
                            </h2>
                            <p className="text-gray-600">Let's add some important details to make your profile stand out</p>
                        </div>

                        <div className="space-y-4">
                            {identityData?.enrichmentOpportunities?.identityGaps &&
                                identityData.enrichmentOpportunities.identityGaps.map((gap, index) => (
                                    gap.suggestedQuestions.map((question) => (
                                        <div key={index} className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow">
                                            <p className="text-lg font-medium text-gray-800 mb-3">{question.question}</p>
                                            <textarea
                                                value={answers[question.questionId] || ''}
                                                onChange={(e) => setAnswers(prev => ({
                                                    ...prev,
                                                    [question.questionId]: e.target.value
                                                }))}
                                                rows={3}
                                                className="w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                                                placeholder="Your answer..."
                                            />
                                        </div>
                                    ))
                                ))}
                        </div>

                        <button
                            onClick={() => setStep(4)}
                            className="w-full py-4 px-6 text-lg font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center space-x-2"
                        >
                            <span>Complete Profile</span>
                            <ArrowRight className="w-5 h-5 ml-2" />
                        </button>
                    </div>
                );

            case 4:
                return (
                    <div className="text-center space-y-8">
                        <div className="w-24 h-24 bg-green-100 rounded-full mx-auto flex items-center justify-center">
                            <CheckCircle className="w-14 h-14 text-green-500" />
                        </div>
                        <div>
                            <h2 className="text-3xl font-bold bg-gradient-to-r from-green-600 to-teal-600 bg-clip-text text-transparent mb-4">
                                You're all set!
                            </h2>
                            <p className="text-gray-600 text-lg">
                                Your professional profile is ready. Let's start optimizing your CV for your dream job.
                            </p>
                        </div>

                        <button
                            onClick={() => window.location.href = '/'}
                            className="w-full py-4 px-6 text-lg font-medium text-white bg-gradient-to-r from-green-600 to-teal-600 rounded-lg hover:opacity-90 transition-opacity duration-200"
                        >
                            Go to Dashboard
                        </button>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-6 flex items-center justify-center">
            <div className="max-w-xl w-full">
                <div className="bg-white/90 backdrop-blur-sm rounded-2xl shadow-2xl p-8">
                    {renderProgressBar()}
                    {renderStep()}
                </div>
            </div>
        </div>
    );
};

export default OnboardingFlow;
