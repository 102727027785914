import {useEffect, useState} from "react";
import {getAuth, onAuthStateChanged, User} from "firebase/auth";
// Utility type for date strings (YYYY-MM-DD format)
export type DateString = string;

// Represents a date range with optional end date for current positions
export interface DateRange {
    start: DateString;
    end?: DateString | 'Present';
}

// Simplified date format used in the work experience
export type DateRangeString = `${DateString}/${string}`; // e.g., "2024-04-01/Present"

export interface ContactInfo {
    linkedIn?: string;
    website?: string;
    email: string;
    fullName: string;
    location?: string;
    phone: string;
}

export interface WorkExperience {
    title: string;
    company: string;
    responsibilities: string;
    location?: string;
    dates?: DateRangeString;
    achievements: string;
}

export interface Skills {
    technical: string[];
    soft: string[];
}

// Optional sections interfaces
export interface Certification {
    name: string;
    issuer: string;
    date: DateString;
    expiryDate?: DateString;
}

export interface Education {
    institution: string;
    degree: string;
    field?: string;
    dates?: DateRange;
    location?: string;
    gpa?: number;
}

export interface Project {
    name: string;
    description: string;
    technologies?: string[];
    link?: string;
    dates?: DateRange;
}

export interface Language {
    language: string;
    proficiency?: string;
}
// Main CV data interface
export interface CVData {
    id: string;
    certifications: Certification[];
    references: string[];
    awards: string[];
    summary: string;
    memberships: string[];
    volunteer: string[];
    workExperience: WorkExperience[];
    publications: string[];
    skills: Skills;
    projects: Project[];
    languages: Language[];
    education: Education[];
    userId: string;
    contactInfo: ContactInfo;
    createdAt: string;
}


interface UseCvsResult {
    cvs: CVData[] | undefined;
    loading: boolean;
    error: string | null;
    refetch: () => Promise<void>;
}

export const useCvs = (): UseCvsResult => {
    const [cvs, setCvs] = useState<CVData[] | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const auth = getAuth();

    const fetchCvs = async () => {
        if (!user) return;
        setLoading(true);
        try {
            const idToken = await user.getIdToken();
            const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/identity`, {
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data: CVData[] = await response.json();
            setCvs(data);
            setError(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to fetch CV data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        fetchCvs();
    }, [user, auth]);

    return { cvs, loading, error, refetch: fetchCvs };
};
