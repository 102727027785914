import { useEffect } from "react";

export const Policy = () => {
    useEffect(() => {
        const scriptId = "termly-jssdk";

        if (!document.getElementById(scriptId)) {
            const script = document.createElement("script");
            script.id = scriptId;
            script.src = "https://app.termly.io/embed-policy.min.js";
            script.async = true;

            document.body.appendChild(script);
        }

        return () => {
            const script = document.getElementById(scriptId);
            if (script) {
                script.remove();
            }
        };
    }, []);

    return (
        <div className="w-full">
            <div
                {...({ name: "termly-embed" } as unknown as React.HTMLAttributes<HTMLDivElement>)}
                data-id="c176ebf4-207f-4261-b7c9-abad5af6aef5"
                className="w-full min-h-[600px]"
            />
        </div>
    );
};
