import {ChangeEvent, FormEvent, useState} from 'react';
import {
    CheckCircle,
    Clock,
    FileIcon,
    FileSearch,
    FileText,
    LinkIcon,
    Loader,
    PlusIcon,
    RefreshCw,
    Upload,
} from 'lucide-react';
import {auth} from '../../config/firebase';
import {Tab} from '@headlessui/react';
import {useCvs} from "../../hooks/useCvs";
import {formatDate} from "date-fns";


const LoadingState = () => (
    <div className="max-w-2xl mx-auto text-center py-12">
        <div className="w-16 h-16 rounded-full bg-blue-50 flex items-center justify-center mx-auto mb-6">
            <Loader className="w-8 h-8 text-blue-600 animate-spin"/>
        </div>
    </div>
);


export const CVOptimizer = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [activeCvTab, setActiveCvTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [urlInput, setUrlInput] = useState('');
    const [textInput, setTextInput] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [selectedCv, setSelectedCv] = useState('');
    const user = auth.currentUser;
    const [error, setError] = useState<string | null>(null)
    // Get existing CVs
    const cvs = useCvs();

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        const allowedTypes = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];

        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
            setFile(selectedFile);
            // setError('');
        } else {
            setFile(null);
            // setSelectedFile('Please upload only .doc or .docx files');
        }
    };
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const idToken = await user?.getIdToken();
            const formData = new FormData();
            formData.append('job_url', urlInput);

            // Add either cv_file or cv_id based on active tab
            if (file) {
                formData.append('cv_file', file);
            } else if (selectedCv) {
                formData.append('cv_id', selectedCv);
            } else {
                return new Error("Please provide a CV file or select an existing CV");
            }

            const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/optimization/job-url`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                return new Error(errorData.detail || 'Failed to create optimization');
            }

            setIsSuccess(true);
            // Reset form
            setUrlInput('');
            setFile(null);
            setSelectedCv('');

        } catch {
            setError('Failed to create optimization');
            setIsSuccess(false);
        } finally {
            setIsLoading(false);
        }
    };


    const handleReset = () => {
        setIsSuccess(false);
        setUrlInput('');
        setTextInput('');
        setFile(null);
        setSelectedCv('');
        setActiveTab(1);
    };

    if (isSuccess) {
        return (
            <div
                className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-6 flex items-center justify-center">
                <div className="max-w-md w-full">
                    <div className="bg-white rounded-2xl shadow-xl p-8 text-center">
                        <div className="mb-6">
                            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4"/>
                            <h2 className="text-2xl font-bold text-gray-800 mb-2">CV Optimization Started!</h2>
                            <p className="text-gray-600 mb-6">
                                Your CV is being processed. This may take up to 5 minutes.
                                We'll notify you once it's ready.
                            </p>
                            <div className="flex items-center justify-center space-x-2 mb-4">
                                <Clock className="w-5 h-5 text-blue-500"/>
                                <span className="text-blue-500 font-medium">Processing...</span>
                            </div>
                        </div>

                        <div className="space-y-4">
                            <button
                                onClick={handleReset}
                                className="w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg font-medium transition-colors duration-200 flex items-center justify-center space-x-2"
                            >
                                <RefreshCw className="w-5 h-5"/>
                                <span>Create Another CV</span>
                            </button>

                            <button
                                className="w-full py-3 px-4 bg-white border-2 border-blue-600 text-blue-600 hover:bg-blue-50 rounded-lg font-medium transition-colors duration-200 flex items-center justify-center space-x-2"
                            >
                                <FileSearch className="w-5 h-5"/>
                                <span>View All Optimizations</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-6 flex items-center justify-center">
            <div className="max-w-md w-full">
                <div className="text-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800 mb-2">CV Optimizer</h1>
                    <p className="text-gray-600">Optimize your CV for your dream job</p>
                </div>
                {cvs.loading ? (<LoadingState/>) : (
                    <div className="bg-white rounded-2xl shadow-xl p-6">
                        {/* CV Selection Section */}
                        <Tab.Group selectedIndex={activeCvTab} onChange={setActiveCvTab}>
                            <Tab.List className="flex space-x-1 rounded-lg bg-blue-50 p-1 mb-6">
                                {!cvs.loading && (cvs.cvs?.length ?? 0) > 0 && (<Tab className={({selected}) =>
                                    `w-full py-2.5 font-medium leading-5 rounded-lg transition-all duration-200 flex items-center justify-center space-x-2
                ${selected
                                        ? 'bg-white text-blue-600 shadow'
                                        : 'text-gray-600 hover:bg-white/[0.5] hover:text-blue-600'
                                    }`
                                }>
                                    <FileIcon className="w-4 h-4"/>
                                    <span>Select CV</span>
                                </Tab>)}
                                <Tab className={({selected}) =>
                                    `w-full py-2.5 font-medium leading-5 rounded-lg transition-all duration-200 flex items-center justify-center space-x-2
                ${selected
                                        ? 'bg-white text-blue-600 shadow'
                                        : 'text-gray-600 hover:bg-white/[0.5] hover:text-blue-600'
                                    }`
                                }>
                                    <PlusIcon className="w-4 h-4"/>
                                    <span>Upload</span>
                                </Tab>
                            </Tab.List>

                            <Tab.Panels>
                                {!cvs.loading && (cvs.cvs?.length ?? 0) > 0 && (
                                    <Tab.Panel>
                                        <div>
                                            <label htmlFor="existing-cv"
                                                   className="block text-sm font-medium text-gray-700 mb-1">
                                                Choose from existing CVs
                                            </label>
                                            <select
                                                id="existing-cv"
                                                value={selectedCv}
                                                onChange={(e) => setSelectedCv(e.target.value)}
                                                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                            >
                                                <option value="">Select a CV</option>
                                                {cvs.cvs?.map((cv) => (
                                                    <option key={cv.id} value={cv.id}>
                                                        {`(Uploaded ${formatDate(new Date(cv.createdAt), 'dd MMM yyyy')})`}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Tab.Panel>)}

                                <Tab.Panel>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Upload a new CV
                                        </label>
                                        <div
                                            className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors duration-200">
                                            <div className="space-y-1 text-center">
                                                <Upload className="mx-auto h-12 w-12 text-gray-400"/>
                                                <div className="flex text-sm text-gray-600">
                                                    <label htmlFor="file-upload"
                                                           className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500">
                                                        <span>Upload a file</span>
                                                        <input
                                                            id="file-upload"
                                                            name="file-upload"
                                                            type="file"
                                                            className="sr-only"
                                                            accept=".docx, .doc "
                                                            onChange={handleFileChange}
                                                        />
                                                    </label>
                                                    <p className="pl-1">or drag and drop</p>
                                                </div>
                                                <p className="text-xs text-gray-500">PDF or docx up to 10MB</p>
                                            </div>
                                        </div>
                                        {file && (
                                            <div className="mt-2 flex items-center space-x-2 text-sm text-gray-600">
                                                {/*<File />*/}
                                                <span>{file.name}</span>
                                            </div>
                                        )}
                                    </div>
                                </Tab.Panel>
                            </Tab.Panels>

                        </Tab.Group>
                        <div className="mb-8">
                            <div className="space-y-4">
                            </div>
                        </div>

                        {/* Job Description Input Tabs */}
                        <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
                            <Tab.List className="flex space-x-1 rounded-lg bg-blue-50 p-1 mb-6">
                                <Tab className={({selected}) =>
                                    `w-full py-2.5 font-medium leading-5 rounded-lg transition-all duration-200 flex items-center justify-center space-x-2
                ${selected
                                        ? 'bg-white text-blue-600 shadow'
                                        : 'text-gray-600 hover:bg-white/[0.5] hover:text-blue-600'
                                    }`
                                }>
                                    <LinkIcon className="w-4 h-4"/>
                                    <span>URL</span>
                                </Tab>
                                <Tab className={({selected}) =>
                                    `w-full py-2.5 font-medium leading-5 rounded-lg transition-all duration-200 flex items-center justify-center space-x-2
                ${selected
                                        ? 'bg-white text-blue-600 shadow'
                                        : 'text-gray-600 hover:bg-white/[0.5] hover:text-blue-600'
                                    }`
                                }>
                                    <FileText className="w-4 h-4"/>
                                    <span>Text</span>
                                </Tab>
                            </Tab.List>

                            <Tab.Panels>
                                <Tab.Panel>
                                    <form onSubmit={handleSubmit} className="space-y-4">
                                        <div>
                                            <label htmlFor="url"
                                                   className="block text-sm font-medium text-gray-700 mb-1">
                                                Job Description URL
                                            </label>
                                            <input
                                                type="url"
                                                id="url"
                                                value={urlInput}
                                                onChange={(e) => setUrlInput(e.target.value)}
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                                placeholder="https://example.com/job-posting"
                                                required
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            disabled={isLoading || (!selectedCv && !file)}
                                            className="w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-400 disabled:cursor-not-allowed text-white rounded-lg font-medium transition-colors duration-200"
                                        >
                                            {isLoading ? 'Processing...' : 'Optimize CV'}
                                        </button>
                                    </form>
                                    {error && <p>{error}</p>}
                                </Tab.Panel>

                                <Tab.Panel>
                                    <form onSubmit={handleSubmit} className="space-y-4">
                                        <div>
                                            <label htmlFor="description"
                                                   className="block text-sm font-medium text-gray-700 mb-1">
                                                Job Description
                                            </label>
                                            <textarea
                                                id="description"
                                                value={textInput}
                                                onChange={(e) => setTextInput(e.target.value)}
                                                rows={6}
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                                placeholder="Paste the job description here..."
                                                required
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            disabled={isLoading || (!selectedCv && !file)}
                                            className="w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-400 disabled:cursor-not-allowed text-white rounded-lg font-medium transition-colors duration-200"
                                        >
                                            {isLoading ? 'Processing...' : 'Optimize CV'}
                                        </button>
                                    </form>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                )}
            </div>
        </div>
    );
};

