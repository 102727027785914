import React, { useState } from 'react';
// import { Plus, Trash2, BookMarked, Award, Building2, Brain } from 'lucide-react';

// Type definitions
type DateString = string;

interface DateRange {
    start: DateString;
    end?: DateString | 'Present';
}

type DateRangeString = `${DateString}/${string}`;

interface ContactInfo {
    linkedIn?: string;
    website?: string;
    email: string;
    fullName: string;
    location?: string;
    phone: string;
}

interface WorkExperience {
    title: string;
    company: string;
    responsibilities: string;
    location?: string;
    dates?: DateRangeString;
    achievements: string;
}

interface Skills {
    technical: string[];
    soft: string[];
}

interface Certification {
    name: string;
    issuer: string;
    date: DateString;
    expiryDate?: DateString;
}

interface Education {
    institution: string;
    degree: string;
    field?: string;
    dates?: DateRange;
    location?: string;
    gpa?: number;
}

interface Project {
    name: string;
    description: string;
    technologies?: string[];
    link?: string;
    dates?: DateRange;
}

interface Language {
    language: string;
    proficiency?: string;
}

interface CVData {
    id: string;
    certifications: Certification[];
    references: string[];
    awards: string[];
    summary: string;
    memberships: string[];
    volunteer: string[];
    workExperience: WorkExperience[];
    publications: string[];
    skills: Skills;
    projects: Project[];
    languages: Language[];
    education: Education[];
    userId: string;
    contactInfo: ContactInfo;
    createdAt: string;
}

interface CVCollectorProps {
    initialData?: CVData;
    onSubmit: (data: CVData | string) => Promise<void>;
    isNewCV?: boolean;
}

export const CVCollector: React.FC<CVCollectorProps> = ({ initialData, onSubmit, isNewCV = false }) => {
    const [activeTab, setActiveTab] = useState('profile');
    const [formData, setFormData] = useState<Partial<CVData>>(
        initialData || {
            contactInfo: {
                email: '',
                fullName: '',
                phone: '',
            },
            workExperience: [],
            skills: {
                technical: [],
                soft: [],
            },
            projects: [],
            summary: '',
            certifications: [],
            education: [],
            languages: [],
            references: [],
            awards: [],
            memberships: [],
            volunteer: [],
            publications: [],
        }
    );
    const [rawText, setRawText] = useState('');

    const updateFormData = (path: string, value: any) => {
        setFormData(prev => {
            const newData = { ...prev };
            const keys = path.split('.');
            let current: any = newData;

            for (let i = 0; i < keys.length - 1; i++) {
                if (!current[keys[i]]) {
                    current[keys[i]] = {};
                }
                current = current[keys[i]];
            }
            current[keys[keys.length - 1]] = value;
            return newData;
        });
    };

    const handleSubmit = async () => {
        try {
            if (isNewCV) {
                await onSubmit(rawText);
            } else {
                await onSubmit(formData as CVData);
            }
        } catch (error) {
            console.error('Error submitting CV:', error);
        }
    };

    const TabButton: React.FC<{ id: string; active: boolean; children: React.ReactNode }> = ({ id, active, children }) => (
        <button
            onClick={() => setActiveTab(id)}
            className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors
                ${active ? 'bg-blue-600 text-white' : 'text-gray-600 hover:bg-gray-100'}`}
        >
            {children}
        </button>
    );

    return (
        <div className="w-full max-w-4xl mx-auto p-6 space-y-6">
            {/* Tabs */}
            <div className="flex space-x-4 border-b pb-4">
                <TabButton id="profile" active={activeTab === 'profile'}>Profile</TabButton>
                <TabButton id="experience" active={activeTab === 'experience'}>Experience</TabButton>
                <TabButton id="education" active={activeTab === 'education'}>Education</TabButton>
                <TabButton id="skills" active={activeTab === 'skills'}>Skills</TabButton>
                <TabButton id="projects" active={activeTab === 'projects'}>Projects</TabButton>
                <TabButton id="additional" active={activeTab === 'additional'}>Additional</TabButton>
            </div>

            {isNewCV ? (
                // Raw text input for new CV
                <div className="space-y-4">
                    <textarea
                        className="w-full h-96 p-4 border rounded-lg"
                        placeholder="Paste your CV text here..."
                        value={rawText}
                        onChange={(e) => setRawText(e.target.value)}
                    />
                </div>
            ) : (
                // Structured form for existing CV
                <div className="space-y-6">
                    {/* Profile Section */}
                    {activeTab === 'profile' && (
                        <div className="space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                                <input
                                    type="text"
                                    placeholder="Full Name"
                                    className="p-2 border rounded"
                                    value={formData.contactInfo?.fullName || ''}
                                    onChange={(e) => updateFormData('contactInfo.fullName', e.target.value)}
                                />
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="p-2 border rounded"
                                    value={formData.contactInfo?.email || ''}
                                    onChange={(e) => updateFormData('contactInfo.email', e.target.value)}
                                />
                            </div>
                            <textarea
                                placeholder="Professional Summary"
                                className="w-full p-2 border rounded h-32"
                                value={formData.summary || ''}
                                onChange={(e) => updateFormData('summary', e.target.value)}
                            />
                        </div>
                    )}

                    {/* Experience Section */}
                    {activeTab === 'experience' && (
                        <div className="space-y-4">
                            {formData.workExperience?.map((exp, index) => (
                                <div key={index} className="p-4 border rounded space-y-2">
                                    <input
                                        type="text"
                                        placeholder="Job Title"
                                        className="w-full p-2 border rounded"
                                        value={exp.title}
                                        onChange={(e) => {
                                            const newExp = [...(formData.workExperience || [])];
                                            newExp[index].title = e.target.value;
                                            updateFormData('workExperience', newExp);
                                        }}
                                    />
                                    {/* Add other experience fields */}
                                </div>
                            ))}
                            <button
                                className="bg-blue-600 text-white px-4 py-2 rounded"
                                onClick={() => {
                                    const newExp = [...(formData.workExperience || [])];
                                    newExp.push({
                                        title: '',
                                        company: '',
                                        responsibilities: '',
                                        achievements: ''
                                    });
                                    updateFormData('workExperience', newExp);
                                }}
                            >
                                Add Experience
                            </button>
                        </div>
                    )}

                    {/* Add other sections similarly */}
                </div>
            )}

            <button
                className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors"
                onClick={handleSubmit}
            >
                {isNewCV ? 'Create CV' : 'Update CV'}
            </button>
        </div>
    );
};

