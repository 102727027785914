import  { useState } from 'react';
import { ArrowRight, Check, X, MenuIcon, X as CloseIcon, ChevronDown } from 'lucide-react';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <header className="bg-white shadow-sm fixed w-full top-0 z-50">
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
                <div className="flex items-center">
                    <span className="text-2xl font-bold text-blue-600">CVSync.io</span>
                </div>

                <div className="hidden md:flex items-center space-x-8">
                    <a href="#how-it-works" className="text-gray-600 hover:text-gray-900">How it Works</a>
                    <a href="#testimonials" className="text-gray-600 hover:text-gray-900">Testimonials</a>
                    <a href="#pricing" className="text-gray-600 hover:text-gray-900">Pricing</a>
                    <a href="#faq" className="text-gray-600 hover:text-gray-900">FAQ</a>
                    <a
                        href="https://www.cvsync.io"
                        className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition"
                    >
                        Get Started
                    </a>
                </div>

                <button
                    className="md:hidden"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                </button>
            </nav>

            {isMenuOpen && (
                <div className="md:hidden bg-white border-t">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        <a href="#how-it-works" className="block px-3 py-2 text-gray-600">How it Works</a>
                        <a href="#testimonials" className="block px-3 py-2 text-gray-600">Testimonials</a>
                        <a href="#pricing" className="block px-3 py-2 text-gray-600">Pricing</a>
                        <a href="#faq" className="block px-3 py-2 text-gray-600">FAQ</a>
                        <a
                            href="https://www.cvsync.io"
                            className="block px-3 py-2 text-white bg-blue-600 rounded-lg text-center"
                        >
                            Get Started
                        </a>
                    </div>
                </div>
            )}
        </header>
    );
};

const Hero = () => (
    <div className="pt-24 pb-16 text-center bg-gradient-to-r from-blue-50 to-indigo-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-5xl font-bold text-gray-900 mb-6">
                Land Your Dream Job with an <span className="text-blue-600">AI-Optimized CV</span>
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
                Automatically tailor your CV to match job descriptions and increase your interview chances using advanced AI technology.
            </p>
            <a
                href="https://www.cvsync.io"
                className="inline-flex items-center px-8 py-3 text-lg font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition"
            >
                Get Started Free <ArrowRight className="ml-2" />
            </a>
        </div>
    </div>
);

const HowItWorks = () => {
    const steps = [
        {
            title: "Upload Your CV",
            description: "Share your current CV and the job description you're targeting.",
            icon: "📄"
        },
        {
            title: "AI Analysis",
            description: "Our AI compares your CV with the job requirements, identifying gaps and opportunities.",
            icon: "🔍"
        },
        {
            title: "Optimize & Enhance",
            description: "Receive tailored suggestions to improve your CV's match rate and structure.",
            icon: "⚡"
        }
    ];

    return (
        <section id="how-it-works" className="py-16 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-4xl font-bold text-center mb-12">How It Works</h2>
                <div className="grid md:grid-cols-3 gap-8">
                    {steps.map((step, index) => (
                        <div key={index} className="text-center p-6 rounded-lg bg-white shadow-lg">
                            <div className="text-4xl mb-4">{step.icon}</div>
                            <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
                            <p className="text-gray-600">{step.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

const Testimonials = () => {
    const testimonials = [
        {
            name: "Sarah Johnson",
            role: "Software Engineer",
            content: "CVSync helped me land interviews at top tech companies. The AI suggestions were spot-on!",
            image: "/api/placeholder/64/64"
        },
        {
            name: "Michael Chen",
            role: "Marketing Manager",
            content: "I got 3x more callbacks after optimizing my CV with CVSync. Worth every penny!",
            image: "/api/placeholder/64/64"
        },
        {
            name: "Emma Williams",
            role: "Product Designer",
            content: "The platform is intuitive and the suggestions are incredibly helpful. Highly recommend!",
            image: "/api/placeholder/64/64"
        }
    ];

    return (
        <section id="testimonials" className="py-16 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-4xl font-bold text-center mb-12">What Users Say</h2>
                <div className="grid md:grid-cols-3 gap-8">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                            <div className="flex items-center mb-4">
                                <img
                                    src={testimonial.image}
                                    alt={testimonial.name}
                                    className="w-12 h-12 rounded-full mr-4"
                                />
                                <div>
                                    <h3 className="font-semibold">{testimonial.name}</h3>
                                    <p className="text-gray-600 text-sm">{testimonial.role}</p>
                                </div>
                            </div>
                            <p className="text-gray-600">{testimonial.content}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const faqs = [
        {
            question: "What is CV Optimizer?",
            answer: "CV Optimizer is an AI-powered tool that helps you tailor your CV/resume to specific job descriptions. It analyzes your CV against the job requirements and provides suggestions to improve your match rate and chances of getting an interview."
        },
        {
            question: "How does the CV optimization process work?",
            answer: "Our system uses advanced AI to analyze both your CV and the job description. It identifies key requirements, skills, and qualifications from the job posting and compares them with your CV. Then, it provides specific recommendations to better align your CV with the job requirements."
        },
        {
            question: "What file formats are supported?",
            answer: "We support .pdf, .doc and .docx file formats for CV uploads. We recommend using .docx format for the best results and compatibility."
        },
        {
            question: "How long does the optimization process take?",
            answer: "The optimization process typically takes 3-5 minutes. However, during peak times or for more complex CVs and job descriptions, it might take slightly longer."
        }
    ];

    return (
        <section id="faq" className="py-16 bg-white">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h2>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg">
                            <button
                                className="w-full px-6 py-4 text-left flex justify-between items-center"
                                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                            >
                                <span className="font-medium">{faq.question}</span>
                                <ChevronDown className={`transform transition-transform ${openIndex === index ? 'rotate-180' : ''}`} />
                            </button>
                            {openIndex === index && (
                                <div className="px-6 pb-4">
                                    <p className="text-gray-600">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

const Pricing = () => {
    const [annual, setAnnual] = useState(false);

    const plans = [
        {
            name: 'Free',
            description: 'Perfect for trying out CV Optimizer',
            price: 0,
            features: [
                '3 CV optimizations per month',
                'Basic CV analysis',
                'Standard templates',
                'Email support',
                'Basic job matching'
            ],
            limitations: [
                'No AI-powered suggestions',
                'No custom templates',
                'No priority support'
            ]
        },
        {
            name: 'Light',
            description: 'For job seekers who want more power',
            price: annual ? 8.99 : 9.99,
            features: [
                '10 CV optimizations per month',
                'Advanced CV analysis',
                'AI-powered suggestions',
                'Custom templates',
                'Priority email support',
                'Advanced job matching',
                'CV performance analytics',
                'Keyword optimization'
            ]
        },
        {
            name: 'Pro',
            description: 'For professionals seeking the best results',
            price: annual ? 16.99 : 19.99,
            features: [
                'Unlimited CV optimizations',
                'Real-time CV analysis',
                'Advanced AI suggestions',
                'Custom branded templates',
                '24/7 Priority support',
                'Expert job matching',
                'Detailed analytics dashboard',
                'Keyword optimization',
                'Industry insights',
                'API access',
                'Multiple CV versions',
                'Interview preparation tools'
            ]
        }
    ];

    return (
        <section id="pricing" className="py-16 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-4xl font-bold text-center mb-8">Simple, Transparent Pricing</h2>

                <div className="flex justify-center mb-12">
                    <div className="flex items-center bg-white rounded-lg p-1 shadow-sm">
                        <button
                            className={`px-4 py-2 rounded-md transition ${!annual ? 'bg-blue-600 text-white' : 'text-gray-600'}`}
                            onClick={() => setAnnual(false)}
                        >
                            Monthly
                        </button>
                        <button
                            className={`px-4 py-2 rounded-md transition ${annual ? 'bg-blue-600 text-white' : 'text-gray-600'}`}
                            onClick={() => setAnnual(true)}
                        >
                            Annual (Save 10%)
                        </button>
                    </div>
                </div>

                <div className="grid md:grid-cols-3 gap-8">
                    {plans.map((plan, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                            <div className="p-6">
                                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                                <p className="text-gray-600 mb-4">{plan.description}</p>
                                <div className="mb-6">
                                    <span className="text-4xl font-bold">${plan.price}</span>
                                    <span className="text-gray-600">/month</span>
                                </div>
                                <a
                                    href="https://www.cvsync.io"
                                    className={`block text-center py-2 px-4 rounded-lg transition ${
                                        index === 2
                                            ? 'bg-blue-600 text-white hover:bg-blue-700'
                                            : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                                    }`}
                                >
                                    Get Started
                                </a>
                            </div>
                            <div className="p-6 bg-gray-50">
                                <ul className="space-y-3">
                                    {plan.features.map((feature, i) => (
                                        <li key={i} className="flex items-center">
                                            <Check className="h-5 w-5 text-green-500 mr-2" />
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                    {plan.limitations?.map((limitation, i) => (
                                        <li key={i} className="flex items-center text-gray-500">
                                            <X className="h-5 w-5 text-red-500 mr-2" />
                                            <span>{limitation}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export const LandingPage = () => {
    return (
        <div className="min-h-screen">
            <Header />
            <main>
                <Hero />
                <HowItWorks />
                <Testimonials />
                <Pricing />
                <FAQ />
            </main>
            <footer className="bg-gray-900 text-white py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <p className="mb-4">© 2024 CVSync.io. All rights reserved.</p>
                    <div className="flex justify-center space-x-6">
                        <a href="#" className="hover:text-blue-400">Terms</a>
                        <a href="#" className="hover:text-blue-400">Privacy</a>
                        <a href="#" className="hover:text-blue-400">Contact</a>
                    </div>
                </div>
            </footer>
        </div>
    );
};
