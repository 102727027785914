import { useState, useEffect } from 'react';
import { IdentityResult } from "../screens/onboarding/Onboarding.tsx";
import {getAuth} from "firebase/auth";

interface UseIdentityResult {
    identity: IdentityResult | undefined;
    loading: boolean;
    error: string | null;
    refetch: () => Promise<void>;
}

export const useIdentity = (): UseIdentityResult => {
    const [identity, setIdentity] = useState<IdentityResult | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const auth = getAuth();
    const fetchIdentity = async () => {
        if (!auth.currentUser) return;

        setLoading(true);
        try {
            const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/identity?user_id=${auth.currentUser.uid}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data: IdentityResult = await response.json();
            setIdentity(data);
            setError(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to fetch identity data');
            setIdentity(undefined);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchIdentity();
    }, [auth]);

    return {
        identity,
        loading,
        error,
        refetch: fetchIdentity
    };
};
