import {
    Certification,
    ContactInfo,
    DateRange,
    DateRangeString,
    DateString,
    Education,
    Project,
    Skills,
    WorkExperience
} from "../../hooks/useCvs";
import {useIdentity} from "../../hooks/useIdentity.ts";
import {EnhancedUserDashboard} from "../dashboard/Dashboard2.tsx";
// Utility types for handling partial data
type PartialWorkExperience = Partial<WorkExperience>;
type PartialEducation = Partial<Education>;
type PartialProject = Partial<Project>;

// Type guards for checking data completeness
const isCompleteWorkExperience = (work: PartialWorkExperience): work is WorkExperience => {
    return !!(work.title && work.company);
};

const isCompleteEducation = (education: PartialEducation): education is Education => {
    return !!(education.institution && education.degree);
};

// Helper function to parse date range string
const parseDateRange = (dateRange: DateRangeString): DateRange => {
    const [start, end] = dateRange.split('/');
    return {start, end};
};

// Helper function to format date range for display
const formatDateRange = (dateRange: DateRange): string => {
    const endDate = dateRange.end === 'Present' ? 'Present' :
        dateRange.end ? new Date(dateRange.end).toLocaleDateString() : '';
    return `${new Date(dateRange.start).toLocaleDateString()} - ${endDate}`;
};

export type {
    DateString,
    DateRange,
    DateRangeString,
    ContactInfo,
    WorkExperience,
    Skills,
    Certification,
    Education,
    Project,
    PartialWorkExperience,
    PartialEducation,
    PartialProject,
};

export {
    isCompleteWorkExperience,
    isCompleteEducation,
    parseDateRange,
    formatDateRange,
};

export const UserIdentity = () => {
    // const formatDate = (dateString: string) => {
    //     const date = new Date(dateString);
    //     return new Intl.DateTimeFormat('en-US', {
    //         month: 'short',
    //         day: 'numeric',
    //         year: 'numeric',
    //     }).format(date);
    // };

    const identityResult = useIdentity();


    return identityResult.identity && <EnhancedUserDashboard data={identityResult.identity}/>
};
