import { useState } from 'react';
import { MapPin, Mail, Phone, Calendar, Building, ChevronDown } from 'lucide-react';
import {IdentityResult} from "../onboarding/Onboarding.tsx";
type ExpandedRoles = {
    [key: string]: boolean;
};
export const EnhancedUserDashboard = (props: {data: IdentityResult}) => {
    const [selectedSkillType, setSelectedSkillType] = useState('technical');
    const [expandedRoles, setExpandedRoles] = useState<ExpandedRoles>({});

    const toggleRole = (roleId: string) => {
        setExpandedRoles(prev => ({
            ...prev,
            [roleId]: !prev[roleId]
        }));
    };

    const getTimelineColor = (index: number) => {
        const colors = ['bg-blue-500', 'bg-purple-500', 'bg-green-500', 'bg-yellow-500', 'bg-red-500'];
        return colors[index % colors.length];
    };

    return (
        <div className="max-w-6xl mx-auto p-6 bg-gray-50">
            {/* Hero Section */}
            <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-xl p-8 mb-8 text-white">
                <div className="flex items-start justify-between">
                    <div>
                        <h1 className="text-3xl font-bold">{props.data.identityCore.contactInfo.name}</h1>
                        <p className="text-xl opacity-90 mt-2">{props.data.identityCore.currentStatus.latestRole}</p>
                        <div className="mt-4 flex flex-wrap gap-6">
              <span className="flex items-center gap-2">
                <MapPin className="w-4 h-4" />
                  {props.data.identityCore.contactInfo.location}
              </span>
                            <span className="flex items-center gap-2">
                <Mail className="w-4 h-4" />
                                {props.data.identityCore.contactInfo.email}
              </span>
                            <span className="flex items-center gap-2">
                <Phone className="w-4 h-4" />
                                {props.data.identityCore.contactInfo.phone}
              </span>
                        </div>
                    </div>
                    <div className="flex flex-col items-end gap-2">
            <span className="bg-white/20 px-4 py-2 rounded-full">
              {props.data.identityCore.currentStatus.yearsOfExperience} Years Experience
            </span>
                        <span className="bg-white/20 px-4 py-2 rounded-full">
              {props.data.identityCore.currentStatus.careerLevel.charAt(0).toUpperCase() +
                  props.data.identityCore.currentStatus.careerLevel.slice(1)} Level
            </span>
                    </div>
                </div>
            </div>

            {/* Skills Section */}
            <div className="mb-8">
                <div className="flex gap-4 mb-6">
                    <button
                        onClick={() => setSelectedSkillType('technical')}
                        className={`px-4 py-2 rounded-lg font-medium transition-colors ${
                            selectedSkillType === 'technical'
                                ? 'bg-blue-500 text-white'
                                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        }`}
                    >
                        Technical Skills
                    </button>
                    <button
                        onClick={() => setSelectedSkillType('soft')}
                        className={`px-4 py-2 rounded-lg font-medium transition-colors ${
                            selectedSkillType === 'soft'
                                ? 'bg-blue-500 text-white'
                                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        }`}
                    >
                        Soft Skills
                    </button>
                </div>

                <div className="bg-white rounded-xl p-6 shadow-sm">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {selectedSkillType === 'technical'
                            ? props.data.skillGraph.technicalSkills.map((skill, index) => (
                                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                                    <div className="flex justify-between items-center mb-2">
                                        <h3 className="font-semibold">{skill.name}</h3>
                                        <span className={`px-2 py-1 rounded-full text-xs ${
                                            skill.level === 'expert'
                                                ? 'bg-green-100 text-green-800'
                                                : 'bg-yellow-100 text-yellow-800'
                                        }`}>
                        {skill.level}
                      </span>
                                    </div>
                                    <p className="text-sm text-gray-600">Last used: {skill.recentUsage}</p>
                                </div>
                            ))
                            : props.data.skillGraph.softSkills.map((skill, index) => (
                                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                                    <h3 className="font-semibold mb-2">{skill.name}</h3>
                                    <p className="text-sm text-gray-600">{skill.context}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            {/* Experience Timeline */}
            <div className="bg-white rounded-xl p-6 shadow-sm">
                <h2 className="text-xl font-bold mb-6 flex items-center gap-2">
                    <Calendar className="w-5 h-5" /> Experience Timeline
                </h2>
                <div className="space-y-6">
                    {props.data.atomicEvents
                        .filter(event => event.eventType === 'role')
                        .sort((a, b) => new Date(b.timeline.startDate) > new Date(a.timeline.startDate) ? 1 : -1)
                        .map((role, index) => (
                            <div key={index} className="relative">
                                <div className="flex">
                                    <div className="flex flex-col items-center mr-4">
                                        <div className={`w-4 h-4 rounded-full ${getTimelineColor(index)} ring-4 ring-${getTimelineColor(index)}/20`} />
                                        {index !== props.data.atomicEvents.length - 1 && (
                                            <div className="w-0.5 h-full bg-gray-200 absolute top-4 left-2" />
                                        )}
                                    </div>
                                    <div className="flex-1 bg-gray-50 p-4 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors"
                                         onClick={() => toggleRole(role.eventId)}>
                                        <div className="flex justify-between items-start">
                                            <div>
                                                <h3 className="text-lg font-semibold">{role.details.title}</h3>
                                                <p className="text-gray-600 flex items-center gap-2">
                                                    <Building className="w-4 h-4" />
                                                    {role.context.organization}
                                                </p>
                                                <p className="text-sm text-gray-500">
                                                    {/*{role.timeline.startDate} - {role.timeline.endDate}*/}
                                                </p>
                                            </div>
                                            <ChevronDown className={`w-5 h-5 transition-transform ${
                                                expandedRoles[role.eventId] ? 'rotate-180' : ''
                                            }`} />
                                        </div>

                                        {expandedRoles[role.eventId] && (
                                            <div className="mt-4 space-y-4">
                                                <p className="text-gray-700">{role.details.description}</p>

                                                {role.skills.technical.length > 0 && (
                                                    <div>
                                                        <h4 className="font-medium mb-2">Technologies Used:</h4>
                                                        <div className="flex flex-wrap gap-2">
                                                            {role.skills.technical.map((tech, idx) => (
                                                                <span key={idx} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm">
                                  {tech}
                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}

                                                {role.impact.qualitative.length > 0 && (
                                                    <div>
                                                        <h4 className="font-medium mb-2">Key Impacts:</h4>
                                                        <ul className="list-disc list-inside space-y-1">
                                                            {role.impact.qualitative.map((impact, idx) => (
                                                                <li key={idx} className="text-gray-700">{impact}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}
