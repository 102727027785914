import {useState} from "react";
import {BookOpen, FileText, Mail, Minus, Plus, Video} from "lucide-react";

export const HelpCenter = () => {
    const [openQuestion, setOpenQuestion] = useState<number | null>(null);

    const faqs = [
        {
            question: "What is CV Optimizer?",
            answer: "CV Optimizer is an AI-powered tool that helps you tailor your CV/resume to specific job descriptions. It analyzes your CV against the job requirements and provides suggestions to improve your match rate and chances of getting an interview."
        },
        {
            question: "How does the CV optimization process work?",
            answer: "Our system uses advanced AI to analyze both your CV and the job description. It identifies key requirements, skills, and qualifications from the job posting and compares them with your CV. Then, it provides specific recommendations to better align your CV with the job requirements."
        },
        {
            question: "What file formats are supported?",
            answer: "We support .pdf, .doc and .docx file formats for CV uploads. We recommend using .docx format for the best results and compatibility."
        },
        {
            question: "How long does the optimization process take?",
            answer: "The optimization process typically takes 3-5 minutes. However, during peak times or for more complex CVs and job descriptions, it might take slightly longer."
        },
        {
            question: "What's included in the different subscription plans?",
            answer: "We offer three plans: Free (3 optimizations/month), Light ($9.99/month, 10 optimizations), and Pro ($19.99/month, unlimited optimizations). Each higher tier includes additional features like advanced analytics, priority support, and custom templates."
        },
        {
            question: "How accurate is the AI analysis?",
            answer: "Our AI system has been trained on millions of CVs and job descriptions, achieving a high accuracy rate. However, we recommend reviewing all suggestions as AI is meant to assist, not replace, human judgment."
        },
        {
            question: "Can I cancel my subscription at any time?",
            answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing period. No refunds are provided for partial months."
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-6">
            <div className="max-w-4xl mx-auto">
                <div className="text-center mb-12">
                    <h1 className="text-3xl font-bold text-gray-800 mb-4">How can we help?</h1>
                    <p className="text-gray-600 max-w-2xl mx-auto">
                        Find answers to common questions about CV Optimizer and learn how to make the most of our
                        services
                    </p>
                </div>

                <div className="bg-white rounded-2xl shadow-sm p-6">
                    <div className="space-y-4">
                        {faqs.map((faq, index) => (
                            <div key={index} className="border-b border-gray-100 last:border-0 pb-4 last:pb-0">
                                <button
                                    onClick={() => setOpenQuestion(openQuestion === index ? null : index)}
                                    className="w-full flex items-center justify-between py-4 text-left"
                                >
                                    <span className="font-medium text-gray-800">{faq.question}</span>
                                    {openQuestion === index ? (
                                        <Minus className="w-5 h-5 text-gray-400"/>
                                    ) : (
                                        <Plus className="w-5 h-5 text-gray-400"/>
                                    )}
                                </button>
                                {openQuestion === index && (
                                    <div className="pb-4 text-gray-600 leading-relaxed">
                                        {faq.answer}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                {/* Quick Links Section */}
                <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="bg-white rounded-xl p-6 shadow-sm">
                        <div className="w-12 h-12 bg-blue-50 rounded-lg flex items-center justify-center mb-4">
                            <BookOpen className="w-6 h-6 text-blue-600"/>
                        </div>
                        <h3 className="font-semibold text-gray-800 mb-2">User Guides</h3>
                        <p className="text-gray-600 text-sm mb-4">Detailed guides on how to use CV Optimizer
                            effectively</p>
                        <button className="text-blue-600 text-sm font-medium hover:text-blue-700 transition-colors">
                            View guides →
                        </button>
                    </div>

                    <div className="bg-white rounded-xl p-6 shadow-sm">
                        <div className="w-12 h-12 bg-blue-50 rounded-lg flex items-center justify-center mb-4">
                            <Video className="w-6 h-6 text-blue-600"/>
                        </div>
                        <h3 className="font-semibold text-gray-800 mb-2">Video Tutorials</h3>
                        <p className="text-gray-600 text-sm mb-4">Step-by-step video guides for all features</p>
                        <button className="text-blue-600 text-sm font-medium hover:text-blue-700 transition-colors">
                            Watch now →
                        </button>
                    </div>

                    <div className="bg-white rounded-xl p-6 shadow-sm">
                        <div className="w-12 h-12 bg-blue-50 rounded-lg flex items-center justify-center mb-4">
                            <FileText className="w-6 h-6 text-blue-600"/>
                        </div>
                        <h3 className="font-semibold text-gray-800 mb-2">Blog & Tips</h3>
                        <p className="text-gray-600 text-sm mb-4">Latest articles and CV optimization tips</p>
                        <button className="text-blue-600 text-sm font-medium hover:text-blue-700 transition-colors">
                            Read articles →
                        </button>
                    </div>
                </div>
                <div
                    className="mt-12 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-2xl p-8 text-white text-center lg:w-1/2">
                    <h2 className="text-2xl font-bold mb-4">Still have questions?</h2>
                    <p className="mb-6 opacity-90">Our support team is here to help you with any questions or
                        concerns</p>
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                        <button className="bg-white/10 hover:bg-white/20 transition-colors rounded-xl p-6 text-left">
                            <div className="flex items-center space-x-4">
                                <div className="p-3 bg-white/10 rounded-lg">
                                    <Mail className="w-6 h-6"/>
                                </div>
                                <div>
                                    <h3 className="font-semibold mb-1">Email Support</h3>
                                    <p className="text-sm opacity-90">Get help within 24 hours</p>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};
